import React from "react";
import Posts from "../components/posts";

const PostsTemplate = (source) => {
  return (
    <Posts
      data={source.pageContext.posts}
      title={source.pageContext.title}
      image={source.pageContext.image}
      pageTitle={source.pageContext.pageTitle}
    />
  );
};

export default PostsTemplate;
