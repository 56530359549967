import React from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "./layout";

const PostsContent = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 72rem;
  width: 100%;
`;

const PostContent = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid #eee;
  margin: 0 0 3rem 0;
`;

const PublishedAt = styled.time`
  color: #aaa;
  font-size: 1.4rem;
  letter-spacing: 0.1rem;
  line-height: 2.1rem;
  margin-bottom: 1rem;

  &:after {
    background-color: #d4a259;
    display: block;
    content: "";
    height: 0.1rem;
    margin-top: 1.7rem;
    width: 6rem;
  }
`;

const PostTitle = styled.h2`
  color: #1d1d1d;
  font-size: 3rem;
  font-family: "Crimson Text", serif;
  font-weight: 600;
  line-height: 3.6rem;
  margin: 0 0 1.5rem 0;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Excerpt = styled.div`
  color: #666;
  font-size: 1.6rem;
  line-height: 3rem;
  margin: 0 0 3rem 0;

  p {
    margin: 0;
  }
`;

const Post = (props) => {
  const publishedAt = moment(props.publishedAt);

  return (
    <PostContent>
      <PublishedAt>
        {publishedAt.tz("Australia/Sydney").format("MMMM D, YYYY")}
      </PublishedAt>
      <PostTitle>
        <a
          href={props.path}
          dangerouslySetInnerHTML={{ __html: props.title }}
        />
      </PostTitle>
      <Excerpt dangerouslySetInnerHTML={{ __html: props.excerpt }} />
    </PostContent>
  );
};

const Posts = (props) => {
  const posts = props.data.map((post) => <Post {...post} />);

  return (
    <Layout title={props.title} image={`images/${props.image}`}>
      <Helmet>
        <title>{props.pageTitle}</title>
      </Helmet>
      <PostsContent>{posts}</PostsContent>
    </Layout>
  );
};

export default Posts;
